<template>
    <div style="width: 100%; height: 100%; position:absolute;">
        <div class="background-img" id="bg-se">
            <div class="loader">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
        </div>
        <canvas id="unity-canvas" style="width: 1200px; height: 800px; background: #231F20" class="alturase"></canvas>

    </div>
</template>
<script>
import { db } from '../../../plugins/firebase'
export default {
    
    data() {
        return {
           token:'', 
        }
    },
    mounted() {
        db.collection("TempTokensClever").doc(this.$store.state.user.uid).get().then((doc) => {
            this.token =  doc.data().token;
            
        });
        
        var elemento = document.querySelectorAll(".alturase");
        var height = window.innerHeight-50;
        var width = window.innerWidth;
        elemento[0].style.height=height+'px';
        elemento[0].style.width=width+'px';
        let responsiveScript = document.createElement('script')
        responsiveScript.setAttribute('src', '/se/Build/16072022b.loader.js')
        document.body.appendChild(responsiveScript)
        responsiveScript.onload = () => {
            createUnityInstance(document.querySelector("#unity-canvas"), {
                dataUrl: "/se/Build/16072022b.data",
                frameworkUrl: "/se/Build/16072022b.framework.js",
                codeUrl: "/se/Build/16072022b.wasm",
                streamingAssetsUrl: "/se/StreamingAssets",
                companyName: "escueladelectura",
                productName: "LeeLee SE",
                productVersion: "1.0.9",
            })
            .then((instance) =>
            {
                window.unityInstance = instance;
                let me = this;
                setTimeout(function () { unityInstance.SendMessage('LogoControlador', 'WebGLFirebaseToken', me.token); }, 3000);
                
            })
            .catch((message) => { console.warn(message); });
        }
    },
}
</script>
<style>
  .background-img{
    width: 100%;
    height: calc(100% - 50px);
    background-image: url('/loadingimg.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: fixed;
    right: 0;
    top: 45px;
    display: flex;
    z-index: 1;
  }
  .loader{
    position: absolute;
    bottom: 50px;
    left: 50%;
  }
</style>